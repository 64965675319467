import React from 'react';
import { NavItems } from '../configs/navItems';
import { Link } from 'react-router-dom';
import '../css/NavBar.css';
import NavItem from './navItem';
import menuIcon from '../images/menu-4-32.png';
export default class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            scrolling: {
                type: Boolean,
            },
            menuClicked: {
                type: Boolean,
                default: false,
            }
        };
    }

    componentDidMount() {
        if (window.innerWidth < 768) {
            this.setState({
                isMobile: true,
            });
        }
        window.onscroll = () => {
            const mobile = this.state.isMobile
            const node = document.getElementById('nav');
            const container = document.getElementById('container');
            const title = document.getElementById('title');
            // const titleLeft = document.getElementById('title-left');
            const node2 = document.getElementById('nav-menu');
            const top = window.scrollY === 0;
            const scrolled = window.scrollY !== 0
            console.log(mobile);
            if (scrolled && !mobile) {
                node.classList.add("nav-scrolled");
                node.classList.remove("nav-top");
                title.classList.add("no-opacity");
                title.classList.remove("slide-center");
                container.classList.add("container-left");
                container.classList.remove("container-center");
                node2.classList.add("block");
                node2.classList.remove("show");
                // titleLeft.classList.add("sroll-show");
                // titleLeft.classList.remove("block");
            } else if(top && !this.state.isMobile) {
                node.classList.add("nav-top");
                node.classList.remove("nav-scrolled")
                // title.classList.remove("block");
                container.classList.add("container-center");
                container.classList.remove("container-left");
                node2.classList.add("show");
                node2.classList.remove("block");
                // titleLeft.classList.add("block");
                // titleLeft.classList.remove("sroll-show");
            }
        }

    }



    handleMobileMenu = () => {
        const node = document.getElementById('nav-menu');
        const navDisplay = document.getElementById('nav-menu').style.display;
        if (navDisplay === "" || navDisplay === 'none') {
            this.setState({
                menuClicked: true,
            })
            document.getElementById('nav-menu').style.display = 'flex';
        } else {
            document.getElementById('nav-menu').style.display = 'none';
            this.setState({
                menuClicked: false,
            })
        }
        const clicked = this.state.menuClicked;
        // const hasClass = node.classList.contains('mobile-slide');
        if (clicked){
            node.classList.add("mobile-slide");
        }
        // else if (!clicked && hasClass){
        //     node.classList.add("mobile-slide");
        // }

    }
    closeMobileMenu = () => {
        const screenWidth = window.innerWidth;
            this.setState({
                menuClicked: false,
            })
        // Only if the screen width is mobile
        if (screenWidth <= 768) {
            const navDisplay = document.getElementById('nav-menu').style.display;
            if (navDisplay === "" || navDisplay === 'flex') {
                document.getElementById('nav-menu').style.display = 'none';
            }
        }
    }
    render() {
        const menuClicked = this.state.menuClicked && this.state.isMobile;
        return (
            <nav id="nav" className={"navBar"}>
                {/* <div className="scroll-container">
                    <Link to='/home' id="title-left" className="nav-title-left">
                        Positive Somebody
                    </Link>
                </div> */}
                <div id="container" className="nav-container">
                    <Link to='/home' id="title" className="nav-title" onClick={this.closeMobileMenu}>
                        Positive Somebody
                    </Link>
                    <ul id="nav-menu" className={`navMenu ${menuClicked ? 'mobile-slide' : ''}`}>
                        {NavItems.map((item, index) => {
                            return (
                                <NavItem item={item} key={index} closeMobileMenu={this.closeMobileMenu} />
                            )
                        })}
                        <li className="navItem nav-links donate">
                            <form action="https://www.paypal.com/donate" method="post" target="_top">
                                <input type="hidden" name="hosted_button_id" value="FKGXD57HJ28XY" />

                                <input type="submit" id="nav-donate" className="paypal-button nav-item donate" value="Donate" name="submit" />
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </li>
                    </ul>
                </div>
                <button className='mobile-navButton' onClick={this.handleMobileMenu}>
                    <div style={{ paddingTop: '2px' }}><img height='30px' width='30px' src={menuIcon} /></div>
                </button>
            </nav>


        )
    }
}
//add programs to nav
