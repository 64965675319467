
import '../css/App.css';
import React from 'react';
import Events from '../routes/events';
import AboutUs from '../routes/aboutUs';
import Donate from '../routes/donate';
import Resources from '../routes/resources';
import Shop from '../routes/shop';
import Programs from '../routes/programs';
import Home from '../routes/home';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// import Footer from './footer';

import NavBar from'./navBar.js';

export default class App extends React.Component{
  render(){
    return (
      <div id="positive-somebody" className="app">
     <BrowserRouter>
      <NavBar /> 
    <Routes>
     <Route path="/" element={<Navigate to="/home" />} /> 
      <Route path="events" element={<Events />}/>
      <Route path= "aboutUs" element={<AboutUs />}/>
      <Route path= "donate" element= {<Donate />}/>
      <Route path= "resources" element={<Resources />}/>
      <Route path="shop" element={<Shop />}/> 
      <Route path="programs" element={<Programs />}/>
      <Route path="home" element={<Home />}/>
    </Routes>
  </BrowserRouter>
      </div>
     
        );
}
}


