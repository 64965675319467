import React from 'react';
import { EventInfo } from '../configs/eventInfo';
import '../css/events.css';
import sponserImg from '../images/events/sponsershipForm.png';
import rainbow from '../images/plainEmojis/rainbowPlain.png';


export default function Events() {
    return (

        <main className='event-content'>
            {EventInfo.map((item, index) => {
                return (
                    <div key={index} className="event-container">
                        <img src={item.image} className='eventImage' height="600px" width="420px"></img>
                        <section className="event">
                            <h1 className="event-header">{item.title}</h1>
                            <p className='event-info'>{item.eventInfo}</p>
                        </section>
                    </div>
                )
            })}
            <div className="sponser">
                <h1>Consider Being a Sponser!</h1>
                <img src={sponserImg} className='eventImage' height="600px" width="420px"></img>
                <img className="btn-img" src={rainbow} height="100px" width="100px" />
                <p>Any other donations are welcome, and will help our mission</p>
                <button className="btn">
                    <form action="https://www.paypal.com/donate" method="post" target="_top">
                        <input type="hidden" name="hosted_button_id" value="FKGXD57HJ28XY" />
                        <input type="submit" id="nav-donate" className="paypal-button nav-item donate" value="Donate" name="submit" />
                        <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                </button>
                
            </div>
        </main>

    );
}

