export const ResourceInfo = [
    {
        name: 'Abuse & Neglect Reporting',
        phone: '1-855-444-3911 '
    },
    {
        name: 'AIDS Program ',
        phone: '1-877-342-2437'
    },
    {
        name: 'Bridge Card (EBT) - Customer Service',
        phone: '1-888-678-8914'
    },
    {
        name: 'Child and Adult Licensing application and info',
        phone: '1-866-685-0006'
    },
    {
        name: 'Child Daycare, Child and Adult Foster Care',
        phone: ''
    },
    {
        name: 'Child and Adult Licensing complaints ',
        phone: '1-866-856-0126'
    },
    {
        name: 'Childrens Special Health Care Services',
        phone: '1-800-359-3722'
    },
    {
        name: 'Community Resources & Referrals - MI United Way',
        phone: '211',
        note: '2-1-1 is the health and human service equivalent of 9-1-1 to give or get help'
    },
    {
        name: 'Disability Ombudsman - Michigan Protection & Advocacy Services ',
        phone: '1-800-288-5923'
    },
    {
        name: 'Domestic Violence Helpline',
        phone: '1-800-799-7233'
    },
    {
        name: 'Early On - Thru Dept of Education. Intervention services for infants and toddlers with disabilities and their families. ',
        phone: '1-800-327-5966'
    },
    {
        name: 'Elder care services for the elderly - Covers all of USA. Help with transportation, meals, etc.',
        phone: '1-800-677-1116'
    },
    {
        name: 'Medicaid Customer Help - MSA/MDHHS Michigan calls only ',
        phone: '1-800-642-3195'
    },
    {
        name: 'Medicaid Provider Help - MSA/MDHHS Calls from anywhere',
        phone: '1-800-292-2550'
    },
    {
        name: 'Medicare - Includes part D for pharmacy ',
        phone: '1-888-633-4227'
    },
    {
        name: 'MiChild - MDHHS ',
        phone: '1-888-988-6300'
    },
    {
        name: 'MI Enrolls - Medicaid Managed Care',
        phone: '1-888-367-6557'
    },
    {
        name: 'MI HR - Employment information for state of MI employees',
        phone: '1-877-766-6447'
    },
    {
        name: 'MI RX - Drug Discount program for low-income',
        phone: '1-888-367-6557'
    },
    {
        name: 'Public Service Commission ',
        phone: '1-800-292-9555'
    },
    {
        name: 'Rehabilitation Services Michigan Rehabiliation Services',
        phone: '1-800-605-6722'
    },
    {
        name: 'Relay Center for Deaf and Hard of Hearing ',
        phone: '711'
    },
    {
        name: 'Safe Delivery of Newborns',
        phone: '1-866-733-7733'
    },
    {
        name: 'Sexual Assault Helpline',
        phone: '1-800-656-4673 '
    },
    {
        name: 'THAW fund - The Heat & Warmth Fund',
        phone: '1-800-866-8429'
    },
    {
        name: 'Ticket to Work - For disabled persons. Thru MI ReHab.',
        phone: '1-800-605-6722'
    },
    {
        name: 'Treasury Referral for Welfare Debt ',
        phone: '1-800-950-6227'
    },
    {
        name: 'Tuition Incentive Program (TIP) - Treasury Dept. ',
        phone: '1-888-447-2687'
    },
    {
        name: 'Welfare Debt Collection',
        phone: '1-800-419-3328'
    },
    {
        name: 'WIC - Women, Infants & Children',
        phone: '1-800-225-5942'
    }
]
