import React from 'react';
import PropTypes from 'prop-types';
import '../css/carousel.css';
import { mod } from '../utils/math';
import { Link } from 'react-router-dom';

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { index: 0 }
  }
  componentDidMount() {
    if (this.props.intervalValue && this.props.intervalValue > 0) {
      setInterval(this.handleNext, this.props.intervalValue);
    }
  }
  handleNext = () => {

    this.setState({ index: (mod(this.state.index + 1, this.props.imageArray.length)) });

  }
  handlePrev = () => {
    this.setState({ index: (mod(this.state.index - 1, this.props.imageArray.length)) });
  }
  render() {
    var current = this.state.index;
    var currImg = this.props.imageArray[current];

    return (
      <div className="carousel">
        <div>
          <div className="img">
            <img src={currImg.image} height={currImg.height} width={currImg.width} />
            <a className="prev" onClick={this.handlePrev}>&#10094;</a>
            <a className="next" onClick={this.handleNext}>&#10095;</a>

          </div>

        </div>
        {/* TODO add links and text to slides */}
        {currImg.link &&
          <Link to={currImg.link}>

            <div className="image-info">
              <p className="slide-information">More Information</p>
              {currImg.link &&
                <div className="btn-container">

                </div>
              }
            </div> 
            </Link>
        }
      </div>

    )
  }
}
Carousel.propTypes = {
  imageArray: PropTypes.array,
  intervalValue: PropTypes.number
}