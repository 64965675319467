import React from 'react';
import '../css/programs.css';
import table from '../images/events/eventTable.JPG';

import {Packs} from '../configs/packs.js';

import {Emojis} from '../configs/emojis';
import Carousel from '../components/carousel';
export default function Programs() {
return(
    <body className='programs-container'>
        
        <div className="emoji-container">
            <h1 className="emoji-header">Meet our emojis</h1>
            <Carousel className="carousel-programs" imageArray={Emojis}/> 
        </div>
        
        <div className="pack-container center-text">
        
            <div className="pack-content-row">  
            <Carousel className="carousel-programs" imageArray={Packs}/> 
            <div className='text-container'>
            <h1>Positive Packs</h1>
            <p>We distribute positive packs to schools and other organizations. The packs include customized journals, pencils, stress balls or breathe stones, positive affirmation wristbands, fidgets, and also positive coping worksheets. </p>
            </div>
            </div>
            
           
            
        </div>
        <div className="pack-container list">
            <h1>High School chapters</h1>
            <div className="pack-content-row">
            <div>
            <p>This program is ran like a school club. The program offers, </p>
            <br />
            <ul>
                <li>Positive coping activities</li>
                <br />
                <li>Lessons</li>
                <br />
                <li>Opportunities to do community outreach <br />at the middle school, and elementary schools levels.</li>
                <br />
                <li>Promotion of positive mental health</li>
            </ul>
            <br />
            <p>For more information, contact us at beapositivesomebody@gmail.com</p>
            </div>
            <img src={table} className="image" />
            </div>
        </div>
        

        <div className="pack-container grant">
            <h1>Roberson-Bell Grant</h1>
            <p>This grant aims to provide positive outlets, such as music, art, sports, and other activities to youth that are unable to afford it otherwise.</p>
        </div>
    </body>
    
);
}